import React, { FC, useEffect, useState } from 'react';
import styles from './PhoneProvider.module.css';
import Button from '@mui/material/Button';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { ReactComponent as ArrowTopIcon } from '../../icons/ArrowTop.svg';
import { ReactComponent as CloseIcon } from '../../icons/Close.svg';
import { isObjectEmpty } from '../../helpers';
import { CLIENT_ID } from '../../constants';
import clsx from 'clsx';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import {
  MiscProviderType,
  TEmailParams,
  TMiscProvider,
  useCreateProviderMutation,
  useUpdateProviderMutation,
} from '../../redux/services/provider';
import { PasswordTextfield } from '../custom/PasswordTextfield';

export type TEmailProvider = {
  id: string;
  avatar: string;
  params?: TEmailParams;
};

type TEmailProviderProps = {
  isOpen: boolean;
  close: (value?: boolean) => void;
  emailProvider?: TMiscProvider;
  // TODO: в emailProvider принимать только необходимые параметры
};

export type EmailProviderInputs = {
  root_mail: string;
  mail_hostname: string;
  mail_port: string;
  mail_password: string;
  mail_code_ttl_sec: string;
  is_public: boolean;
};

const schema = yup.object({
  root_mail: yup
    .string()
    .required('Обязательное поле')
    .max(255, 'Адрес электронной почты не может превышать 255 символов')
    .email('Неверный формат адреса электронной почты'),
  mail_hostname: yup
    .string()
    .max(255, 'Адрес сервера исходящей почты не может превышать 2000 символов')
    .required('Обязательное поле'),
  mail_port: yup
    .string()
    .required('Обязательное поле')
    .min(1, 'Порт сервера исходящей почты не может быть меньше 1 символа')
    .max(5, 'Порт сервера исходящей почты не может превышать 5 символов')
    .matches(/^[^\n ]*$/, {
      message: 'Порт сервера исходящей почты не может содержать пробелы',
    }),
  mail_password: yup.string().required('Обязательное поле'),
  mail_code_ttl_sec: yup
    .string()
    .required('Обязательное поле')
    .matches(/^[^\n ]*$/, {
      message: 'Время жизни кода подтверждения не может содержать пробелы',
    }),
});

export const EmailProvider: FC<TEmailProviderProps> = ({ isOpen, close, emailProvider }) => {
  const methods = useForm<EmailProviderInputs>({
    resolver: yupResolver(schema),
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });
  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { dirtyFields, errors },
    clearErrors,
    reset,
  } = methods;

  const [saveModalOpen, setSaveModalOpen] = useState(false);
  const closeSaveModal = () => setSaveModalOpen(false);
  const [createProvider, createResult] = useCreateProviderMutation();
  const [updateProvider, updateResult] = useUpdateProviderMutation();

  useEffect(() => {
    if (isOpen && emailProvider) {
      setFields(emailProvider);
    }
    return () => {
      reset();
    };
  }, [isOpen]);

  useEffect(() => {
    if (createResult.isSuccess || updateResult.isSuccess) close(true);
  }, [createResult, updateResult]);

  const setFields = async (provider?: Partial<TMiscProvider>) => {
    try {
      const { params, is_public } = provider || {};
      setValue('is_public', !!is_public);
      if (params) {
        (Object.keys(params) as Array<keyof TEmailParams>).forEach((field) => {
          setValue(field, (params as TEmailParams)?.[field] || '', { shouldDirty: !provider });
        });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onSubmit: SubmitHandler<EmailProviderInputs> = (data) => {
    if (emailProvider) {
      updateProvider({
        body: {
          type: MiscProviderType.EMAIL,
          provider_id: emailProvider.id,
          ...data,
        },
        client_id: CLIENT_ID,
      });
    } else {
      createProvider({
        body: {
          type: MiscProviderType.EMAIL,
          name: 'Электронная почта',
          ...data,
          is_active: true,
        },
        client_id: CLIENT_ID,
      });
    }
  };

  const handleClose = () => {
    if (isObjectEmpty(dirtyFields)) close();
    else setSaveModalOpen(true);
  };

  return (
    <Drawer
      classes={{ paper: styles['drawer-paper'] }}
      slotProps={{
        backdrop: { style: { background: 'none' } },
      }}
      onClose={handleClose}
      open={isOpen}
      anchor="right"
      variant="temporary"
    >
      <form onSubmit={handleSubmit(onSubmit)} className={styles.wrapper}>
        <div className={styles.header}>
          <IconButton onClick={handleClose} className={styles['button-back']}>
            <ArrowTopIcon className={styles['arrow-icon']} />
          </IconButton>
          <Typography className={clsx('text-24-medium', 'font-golos', styles.title)}>
            Настройки почты
          </Typography>
          <IconButton className={styles['close-button']} onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </div>
        <div className={styles['provider-form']}>
          <Typography
            className={clsx('text-14', 'color-0B1641', styles['input-title'], styles.asterisk)}
          >
            Основной почтовый адреc
          </Typography>
          <TextField
            {...register('root_mail', {
              onChange: () => {
                if (errors.root_mail) {
                  clearErrors('root_mail');
                }
              },
            })}
            className="custom"
            FormHelperTextProps={{
              className: clsx('text-14', 'color-858BA0'),
            }}
            error={!!errors.root_mail}
            helperText={errors.root_mail ? errors.root_mail.message : ''}
            fullWidth
            variant="standard"
          />
          <Typography className={clsx('text-14', 'color-858BA0', styles['input-subtitle'])}>
            Адрес почтовой службы, который также будет использоваться для рассылки писем
          </Typography>

          <Typography
            className={clsx('text-14', 'color-0B1641', styles['input-title'], styles.asterisk)}
          >
            Адрес сервера исходящей почты
          </Typography>
          <TextField
            {...register('mail_hostname', {
              onChange: () => {
                if (errors.mail_hostname) {
                  clearErrors('mail_hostname');
                }
              },
            })}
            className="custom"
            FormHelperTextProps={{
              className: clsx('text-14', 'color-858BA0'),
            }}
            error={!!errors.mail_hostname}
            helperText={errors.mail_hostname ? errors.mail_hostname.message : ''}
            fullWidth
            variant="standard"
            autoComplete="off"
          />
          <Typography className={clsx('text-14', 'color-858BA0', styles['input-subtitle'])}>
            Адрес почтовой службы для рассылки писем
          </Typography>

          <Typography
            className={clsx('text-14', 'color-0B1641', styles['input-title'], styles.asterisk)}
          >
            Порт сервера исходящей почты
          </Typography>
          <TextField
            {...register('mail_port', {
              onChange: () => {
                if (errors.mail_port) {
                  clearErrors('mail_port');
                }
              },
            })}
            className="custom"
            FormHelperTextProps={{
              className: clsx('text-14', 'color-858BA0'),
            }}
            error={!!errors.mail_port}
            helperText={errors.mail_port ? errors.mail_port.message : ''}
            fullWidth
            variant="standard"
            autoComplete="off"
          />
          <Typography className={clsx('text-14', 'color-858BA0', styles['input-subtitle'])}>
            Порт почтовой службы для рассылки писем
          </Typography>

          <Typography
            className={clsx('text-14', 'color-0B1641', styles['input-title'], styles.asterisk)}
          >
            Пароль почты
          </Typography>
          <PasswordTextfield
            {...register('mail_password', {
              required: true,
              onChange: () => {
                if (errors.mail_password) clearErrors('mail_password');
              },
            })}
            className="custom"
            FormHelperTextProps={{
              className: clsx('text-14', 'color-858BA0'),
            }}
            error={!!errors.mail_password}
            helperText={errors.mail_password ? errors.mail_password.message : ''}
            fullWidth
            variant="standard"
            autoComplete="off"
          />
          <Typography className={clsx('text-14', 'color-858BA0', styles['input-subtitle'])}>
            Обычный пароль или пароль приложения, который создается в настройках аккаунта
            <br />
            почтового сервиса
          </Typography>

          <Typography
            className={clsx('text-14', 'color-0B1641', styles['input-title'], styles.asterisk)}
          >
            Время жизни кода подтверждения
          </Typography>
          <TextField
            {...register('mail_code_ttl_sec', {
              onChange: () => {
                if (errors.mail_port) {
                  clearErrors('mail_code_ttl_sec');
                }
              },
            })}
            className="custom"
            FormHelperTextProps={{
              className: clsx('text-14', 'color-858BA0'),
            }}
            error={!!errors.mail_code_ttl_sec}
            helperText={errors.mail_code_ttl_sec ? errors.mail_code_ttl_sec.message : ''}
            fullWidth
            variant="standard"
            autoComplete="off"
          />
          <Typography className={clsx('text-14', 'color-858BA0', styles['input-subtitle'])}>
            Время жизни кодов подтверждения адреса электронной почты в секундах
          </Typography>

          <div className={styles['switch-wrapper']}>
            <Typography className={clsx('text-14', 'color-0B1641')}>
              Использовать для входа по коду
            </Typography>
            <Controller
              control={control}
              name="is_public"
              defaultValue={false}
              render={({ field }) => (
                <Switch checked={field.value} onChange={(e) => field.onChange(e.target.checked)} />
              )}
            />
          </div>
          <Typography className={clsx('text-14', 'color-858BA0', styles['input-subtitle'])}>
            Будут доступны в приложениях как способ входа с помощью одноразовых паролей
          </Typography>
          <div className={styles['bottom-buttons']}>
            <Button onClick={handleClose} variant="custom" color="secondary">
              Отмена
            </Button>
            <Button
              style={{ marginLeft: 24 }}
              disabled={createResult.isLoading || updateResult.isLoading}
              variant="custom"
              type="submit"
            >
              Сохранить
            </Button>
          </div>
        </div>
      </form>
      <Modal open={saveModalOpen} onClose={closeSaveModal}>
        <div className={styles['save-modal']}>
          <div style={{ display: 'flex' }}>
            <Typography className={clsx('header-2-medium', 'font-golos', 'color-0B1641')}>
              Сохранение изменений
            </Typography>
            <IconButton onClick={closeSaveModal} style={{ marginLeft: 'auto', marginBottom: 16 }}>
              <CloseIcon />
            </IconButton>
          </div>
          <Typography style={{ marginBottom: 32 }} className={clsx('text-14', 'color-0B1641')}>
            Изменения не сохранены. Продолжить без сохранения?
          </Typography>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button variant="custom" color="secondary" onClick={closeSaveModal}>
              Отмена
            </Button>
            <Button
              onClick={() => {
                close();
                setSaveModalOpen(false);
              }}
              variant="custom"
              style={{ marginLeft: 24 }}
            >
              Продолжить
            </Button>
          </div>
        </div>
      </Modal>
    </Drawer>
  );
};
