import { ReactComponent as ArrowTopIcon } from '../../icons/ArrowTop.svg';
import { ReactComponent as CloseIcon } from '../../icons/Close.svg';
import React, {
  FC,
  useEffect,
  useState,
  // #371 KeyboardEvent,
  useRef,
} from 'react';
import clsx from 'clsx';
import styles from './EditApplication.module.css';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import { SubmitHandler, useForm, FormProvider } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  TApplication,
  TClient,
  useUpdateApplicationMutation,
  clientApi,
  useGetApplicationByIdQuery,
} from '../../redux/services/client';
import { RootState } from '../../redux/store';
import { useDispatch, useSelector } from 'react-redux';
import { setApplicationFormChanged } from '../../redux/appSlice';
// #609 import { CustomRadioButton } from '../CustomRadioButton';
import { EditProviders } from '../EditProviders';
import {
  TOauthProvider,
  TMiscProvider,
  OauthProviderType,
  MiscProviderType,
  useGetProvidersQuery,
  EGetProviderAction,
  useDeactivateProvidersMutation,
} from '../../redux/services/provider';
import { getProviderTitleByType, isObjectEmpty, isUrl } from '../../helpers';
import { EditApplicationHeader, TProviderItem } from './EditApplicationHeader';
import { EditApplicationFooter } from './EditApplicationFooter';
import { redirectUriSchema, logoutUriSchema, requestUriSchema } from './CreateApplication';
import { useNavigate } from 'react-router-dom-v5-compat';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { IconWrapper } from '../IconWrapper';
import Skeleton from '@mui/material/Skeleton';
import Tooltip from '@mui/material/Tooltip';
import { ReactComponent as Star } from '../../icons/Star.svg';
import { ReactComponent as StarFilled } from '../../icons/StarFilled.svg';
import { ReactComponent as ArrowDownIcon } from '../../icons/ArrowDown.svg';
import { ReactComponent as IdIcon } from '../../icons/Id.svg';
import { ProviderScope, useGetRulesProvidersQuery } from '../../redux/services/settings';
import { BACKEND_URL, CLIENT_ID } from '../../constants';

export type TWidgetColors = { button_color: string; link_color: string; font_color: string };

export type TEditAppicationInputs = {
  name: string;
  description: string;
  domain: string;
  redirect_uris: {
    name: string;
    value: string;
  }[];
  post_logout_redirect_uris: {
    name: string;
    value: string;
  }[];
  request_uris: {
    name: string;
    value: string;
  }[];
  response_types: string[];
  grant_types: string[];
  // #371 refresh_token_ttl: string;
  // #371 access_token_ttl: string;
  avatar: File | string | null;
  cover: File | string | null;
  widget_colors: TWidgetColors;
  show_avatar_in_widget: boolean;
  hide_widget_header: boolean;
  hide_widget_footer: boolean;
  widget_title: string;
  client_id: string;
  client_secret: string;
  token_endpoint_auth_method: string;
  introspection_endpoint_auth_method: string;
  revocation_endpoint_auth_method: string;
  id_token_signed_response_alg: string;
  subject_type: string;
  require_auth_time: boolean;
  require_signed_request_object: boolean;
};

const schema = yup
  .object({
    name: yup
      .string()
      .max(50, 'Название не может превышать 50 символов')
      .required('Обязательное поле'),
    description: yup.string().max(255),
    domain: yup
      .string()
      // #520 .url('Неверный формат ссылки')
      .max(2000, 'Ссылка не может превышать 2000 символов')
      .test('is-url', 'Неверный формат ссылки', (value?: string) => {
        if (!value) return true;
        return isUrl(value);
      })
      .required('Обязательное поле'),
    redirect_uris: yup.array().of(redirectUriSchema).required(),
    post_logout_redirect_uris: yup.array().of(logoutUriSchema).required(),
    request_uris: yup.array().of(requestUriSchema).required(),
    // #371
    // refresh_token_ttl: yup
    //   .number()
    //   .min(1800, 'Время жизни токена обновления не может быть меньше 1800 секунд')
    //   .required('Обязательное поле')
    //   .nullable(true)
    //   .transform((v) => (!v ? null : v)),
    // access_token_ttl: yup
    //   .number()
    //   .min(60, 'Время жизни токена доступа не может быть меньше 60 секунд')
    //   .max(
    //     ACCESS_TOKEN_TTL,
    //     `Время жизни токена доступа не может превышать ${ACCESS_TOKEN_TTL} секунд` +
    //       (ACCESS_TOKEN_TTL / 60 / 60 / 24 >= 1
    //         ? ` (${
    //             ACCESS_TOKEN_TTL / 60 / 60 / 24 +
    //             ' ' +
    //             getDeclinationByNumber(ACCESS_TOKEN_TTL / 60 / 60 / 24, ['день', 'дня', 'дней'])
    //           }).`
    //         : ''),
    //   )
    //   .required('Обязательное поле')
    //   .nullable(true)
    //   .transform((v) => (!v ? null : v)),
    widget_colors: yup
      .object({
        button_color: yup
          .string()
          .required('Обязательное поле')
          .matches(/^#[0-9a-fA-F]{3}$|^#[0-9a-fA-F]{6}$/, 'Цвет должен быть в формате hex'),
        font_color: yup
          .string()
          .required('Обязательное поле')
          .matches(/^#[0-9a-fA-F]{3}$|^#[0-9a-fA-F]{6}$/, 'Цвет должен быть в формате hex'),
        link_color: yup
          .string()
          .required('Обязательное поле')
          .matches(/^#[0-9a-fA-F]{3}$|^#[0-9a-fA-F]{6}$/, 'Цвет должен быть в формате hex'),
      })
      .required(),
    client_id: yup
      .string()
      .required('Обязательное поле')
      .matches(/^[^\n ]*$/, {
        message: 'Идентификатор не может содержать пробелы',
      })
      .matches(/^[A-Za-z0-9_-]+$/, {
        message:
          'Может содержать латинские буквы (a-z), цифры (0-9), дефис (-) и нижнее подчёркивание (_)',
      }),
    client_secret: yup.string().required('Обязательное поле'),
  })
  .required();

type TEditApplication = {
  selectedClient: TClient;
  userId: string;
};

export const EditApplication: FC<TEditApplication> = ({ selectedClient, userId }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // #609 const [isVisible, setIsVisible] = useState(false);
  const [avatarSrc, setAvatarSrc] = useState<string | null>(null);
  const [coverSrc, setCoverSrc] = useState<string | null>(null);
  // #371 const [showRefreshTokenInput, setShowRefreshTokenInput] = useState(false);
  const [saveModalOpen, setSaveModalOpen] = useState(false);
  const [providerModalOpen, setProviderModalOpen] = useState(false);
  const [selectedProvider, setSelectedProvider] = useState<TProviderItem | undefined>(undefined);

  const savedCallback = useRef<() => void>();
  const applicationFormChanged = useSelector(
    (state: RootState) => state.app.applicationFormChanged,
  );
  const methods = useForm<TEditAppicationInputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      ...selectedClient,
      description: selectedClient.description || '',
      redirect_uris: selectedClient?.redirect_uris?.map((uri) => ({ value: uri })) || [
        { value: '' },
      ],
      post_logout_redirect_uris: selectedClient?.post_logout_redirect_uris.length
        ? selectedClient.post_logout_redirect_uris.map((uri) => ({ value: uri }))
        : [{ value: '' }],
      request_uris: selectedClient?.request_uris.length
        ? selectedClient.request_uris.map((uri) => ({ value: uri }))
        : [{ value: '' }],
      response_types: selectedClient?.response_types,
      grant_types: selectedClient?.grant_types,
      require_auth_time: selectedClient?.require_auth_time,
      require_signed_request_object: selectedClient?.require_signed_request_object,
      avatar: selectedClient?.avatar,
      cover: selectedClient?.cover,
      client_id: selectedClient.client_id,
      client_secret: selectedClient.client_secret,
    },
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });
  const {
    handleSubmit,
    formState: { errors, dirtyFields },
    setError,
  } = methods;

  // #609
  // useEffect(() => {
  //   if (selectedClient) {
  //     setIsVisible(selectedClient.is_visible);
  //   }
  // }, [selectedClient]);

  const [updateApplication, { isLoading: updateApplicationLoading }] =
    useUpdateApplicationMutation();

  useEffect(() => {
    if (updateApplicationLoading) navigate('/applications');
  }, [updateApplicationLoading]);

  useEffect(() => {
    return () => {
      dispatch(setApplicationFormChanged(false));
    };
  }, []);

  useEffect(() => {
    const isDirty =
      !isObjectEmpty(dirtyFields) &&
      Object.values(dirtyFields).some((field) => {
        if (typeof field === 'object') {
          const fieldValues = Object.values(field);
          return fieldValues.some((elem) => elem === true || elem?.value === true);
        }
        return field === true;
      });
    if (applicationFormChanged !== isDirty) dispatch(setApplicationFormChanged(isDirty));
  }, [Object.values(dirtyFields)]);

  const closeSaveModal = () => setSaveModalOpen(false);

  const onSubmit: SubmitHandler<TEditAppicationInputs> = async (data) => {
    if (data.redirect_uris.every((uri) => !uri.value)) {
      setError(`redirect_uris.0.value`, { message: 'Обязательное поле' });
      return;
    }
    if (Object.keys(errors).length) return;
    const payload = (Object.keys(dirtyFields) as Array<keyof typeof dirtyFields>).reduce(
      (
        acc: Partial<
          Omit<TApplication['client'], 'avatar' | 'cover'> & {
            avatar: File | null;
            cover: File | null;
          }
        >,
        field,
      ) => {
        if (
          field === 'post_logout_redirect_uris' ||
          field === 'redirect_uris' ||
          field === 'request_uris'
        ) {
          acc[field] = data[field].reduce((dataAcc: string[], uri) => {
            if (uri.value) dataAcc.push(uri.value);
            return dataAcc;
          }, []);
        } else if (field === 'avatar' || field === 'cover') {
          if (typeof data[field] !== 'string') acc[field] = data[field] as File;
        } else if (field === 'widget_colors') acc.widget_colors = data.widget_colors;
        else if (
          field === 'show_avatar_in_widget' ||
          field === 'hide_widget_footer' ||
          field === 'hide_widget_header' ||
          field === 'require_auth_time' ||
          field === 'require_signed_request_object'
        )
          acc[field] = data[field];
        else if (field === 'response_types' || field === 'grant_types') acc[field] = data[field];
        else acc[field] = data[field];
        return acc;
      },
      {},
    );

    await updateApplication({
      currentClientId: selectedClient?.client_id,
      params: {
        ...payload,
        registration_access_token: selectedClient?.registration_access_token?.jti,
        grant_types: payload?.grant_types ?? selectedClient?.grant_types,
        redirect_uris: payload?.redirect_uris ?? selectedClient?.redirect_uris,
        post_logout_redirect_uris:
          payload?.post_logout_redirect_uris ?? selectedClient?.post_logout_redirect_uris,
        request_uris: payload?.request_uris ?? selectedClient?.request_uris,
        require_signed_request_object:
          payload?.require_signed_request_object ?? selectedClient?.require_signed_request_object,
        id_token_signed_response_alg:
          payload?.id_token_signed_response_alg ?? selectedClient?.id_token_signed_response_alg,
        response_types: payload?.response_types ?? selectedClient?.response_types,
        introspection_endpoint_auth_method:
          payload?.introspection_endpoint_auth_method ??
          selectedClient?.introspection_endpoint_auth_method,
        require_auth_time: payload?.require_auth_time ?? selectedClient?.require_auth_time,
        revocation_endpoint_auth_method:
          payload?.revocation_endpoint_auth_method ??
          selectedClient?.revocation_endpoint_auth_method,
        token_endpoint_auth_method:
          payload?.token_endpoint_auth_method ?? selectedClient?.token_endpoint_auth_method,
        subject_type: payload?.subject_type ?? selectedClient?.subject_type,
      },
    });
    dispatch(
      clientApi.endpoints.getApplicationById.initiate(
        { client_id: selectedClient.client_id, user_id: userId },
        {
          subscribe: false,
          forceRefetch: true,
        },
      ),
    );
  };

  const { data: providers = [], isLoading: providersLoading } = useGetProvidersQuery({
    client_id: selectedClient.client_id,
    onlyActive: false,
    action: EGetProviderAction.change,
  });
  const [deactivateProvider] = useDeactivateProvidersMutation();
  const [selectedProviderType, setSelectedProviderType] = useState<ProviderScope | undefined>(
    undefined,
  );

  const { data: rules = [] } = useGetRulesProvidersQuery();
  const { data: client, isFetching: getApplicationFetching } = useGetApplicationByIdQuery(
    { user_id: userId || '', client_id: selectedClient.client_id || '' },
    {
      skip: !userId || !selectedClient.client_id,
    },
  );

  const handleChangeRequiredProviders = async (provider: TOauthProvider | TMiscProvider) => {
    if (!userId || updateApplicationLoading || getApplicationFetching || !selectedClient) return;
    const provider_id =
      ((provider.type || '') in OauthProviderType ? 'oauth_' : 'misc_') + provider.id;
    await updateApplication({
      currentClientId: selectedClient.client_id,
      params: {
        required_providers_ids: selectedClient.required_providers_ids.includes(provider_id)
          ? selectedClient.required_providers_ids.filter((id) => id !== provider_id)
          : [...selectedClient.required_providers_ids, provider_id],
        grant_types: selectedClient.grant_types,
        registration_access_token: selectedClient.registration_access_token?.jti,
        client_id: selectedClient.client_id,
        redirect_uris: selectedClient.redirect_uris,
        post_logout_redirect_uris: selectedClient.post_logout_redirect_uris,
        require_signed_request_object: selectedClient.require_signed_request_object,
        request_uris: selectedClient.request_uris,
        id_token_signed_response_alg: selectedClient.id_token_signed_response_alg,
        response_types: selectedClient.response_types,
        introspection_endpoint_auth_method: selectedClient.introspection_endpoint_auth_method,
        require_auth_time: selectedClient.require_auth_time,
        revocation_endpoint_auth_method: selectedClient.revocation_endpoint_auth_method,
        token_endpoint_auth_method: selectedClient.token_endpoint_auth_method,
        subject_type: selectedClient.subject_type,
      },
    });
    dispatch(
      clientApi.endpoints.getApplicationById.initiate(
        { client_id: selectedClient.client_id, user_id: userId },
        {
          subscribe: false,
          forceRefetch: true,
        },
      ),
    );
  };

  const listLoginProviders: TProviderItem[] = [];

  for (const provider of providers) {
    const rule = rules.find((rule) => rule.type === provider.type);
    if (!rule) {
      continue;
    }

    if (!rule.allowedScopes) {
      continue;
    }

    if (
      rule.allowedScopes.includes(ProviderScope.login) ||
      rule.allowedScopes.includes(ProviderScope.otp) ||
      rule.allowedScopes.includes(ProviderScope.trusted)
    ) {
      listLoginProviders.push({
        rule,
        provider,
      });
    }
  }

  const handleProviderClick = (providerItem: TProviderItem, providerScope: ProviderScope) => {
    if (
      providerScope === ProviderScope.login &&
      !providerItem.rule.allowedScopes.includes(ProviderScope.login)
    ) {
      return;
    }

    if (
      providerItem.provider.type !== MiscProviderType.CREDENTIALS &&
      providerItem.provider.type !== MiscProviderType.EMAIL &&
      providerItem.provider.type !== MiscProviderType.QRCODE &&
      providerItem.provider.type !== MiscProviderType.PHONE &&
      providerItem.provider.client_id === selectedClient.client_id
    ) {
      setSelectedProvider(providerItem);
      setSelectedProviderType(providerScope);
      setProviderModalOpen(true);
    }
  };

  return (
    <div className={styles.wrapper}>
      <Button
        onClick={() => {
          if (applicationFormChanged) {
            savedCallback.current = () => navigate(-1);
            return setSaveModalOpen(true);
          }
          navigate(-1);
        }}
        variant="custom3"
        className={clsx('text-15', styles['button-back'])}
        startIcon={<ArrowTopIcon className={styles['arrow-icon']} />}
      >
        Приложение
      </Button>
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <EditApplicationHeader
            coverSrc={coverSrc}
            setCoverSrc={setCoverSrc}
            avatarSrc={avatarSrc}
            client={selectedClient}
            setAvatarSrc={setAvatarSrc}
          />
          <Accordion className={clsx(styles.panel, styles.accordion)}>
            <AccordionSummary
              className={styles['accorion-summary']}
              classes={{ content: styles['accorion-summary-content'] }}
              expandIcon={<ArrowDownIcon fill="#0B1641" />}
            >
              <Typography className={clsx('font-golos', 'text-17-regular', 'color-0B1641')}>
                Способы входа
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={styles['accordion-details']}>
              <Button
                variant="custom2"
                className={styles['add-button']}
                onClick={() => {
                  setSelectedProviderType(ProviderScope.login);
                  setProviderModalOpen(true);
                }}
              >
                Настроить
              </Button>
              <div className={styles.providers}>
                {providersLoading &&
                  [null, null].map((_, index) => (
                    <div key={index} className={styles.provider}>
                      <IconWrapper>
                        <Skeleton width={20} height={30} />
                      </IconWrapper>
                      <div>
                        <Typography className={clsx('text-14', 'color-0B1641')}>
                          <Skeleton />
                        </Typography>
                        <Typography className={clsx('text-12', 'color-858BA0')}>OAuth 2</Typography>
                      </div>
                      <IconButton className={styles['icon-button-wrapper']}>
                        <CloseIcon className={styles['icon-button']} />
                      </IconButton>
                    </div>
                  ))}
                {listLoginProviders
                  .filter((providerItem) => providerItem.provider.is_active)
                  .map((providerItem) => {
                    const required = selectedClient.required_providers_ids.includes(
                      ((providerItem.provider.type || '') in OauthProviderType
                        ? 'oauth_'
                        : 'misc_') + providerItem.provider.id,
                    );
                    return (
                      <div
                        key={providerItem.provider.id + providerItem.provider.type}
                        className={styles.provider}
                        onClick={() => handleProviderClick(providerItem, ProviderScope.login)}
                      >
                        <div
                          style={{
                            backgroundImage: `url(${
                              BACKEND_URL + '/' + providerItem.provider.avatar
                            })`,
                          }}
                          className={styles['provider-icon-wrapper']}
                        >
                          {!providerItem.provider.avatar && <IdIcon />}
                        </div>
                        <div className={styles['provider-name-wrapper']}>
                          <Typography
                            className={clsx('text-14', 'color-0B1641', styles['provider-name'])}
                          >
                            {providerItem.provider.name}
                          </Typography>
                          <Typography className={clsx('text-12', 'color-858BA0')}>
                            {getProviderTitleByType(providerItem.provider.type)}
                          </Typography>
                        </div>
                        {providerItem.rule.requireable && (
                          <Tooltip
                            arrow
                            title={
                              <div>
                                <Typography className="text-17">
                                  Сделать {required ? 'необязательным' : 'обязательным'}
                                </Typography>
                                <Typography className="text-12">
                                  Для входа в приложение {required ? 'не' : ''} требуется наличие
                                  способа входа в профиле пользователя
                                </Typography>
                              </div>
                            }
                            classes={{
                              tooltip: styles['input-tooltip'],
                              arrow: styles['input-tooltip-arrow'],
                            }}
                          >
                            <IconButton
                              style={{ marginRight: 8 }}
                              onClick={(e) => {
                                e.stopPropagation();
                                handleChangeRequiredProviders(providerItem.provider);
                              }}
                              className={styles['icon-button-wrapper']}
                            >
                              {selectedClient.required_providers_ids.includes(
                                ((providerItem.provider.type || '') in OauthProviderType
                                  ? 'oauth_'
                                  : 'misc_') + providerItem.provider.id,
                              ) ? (
                                <StarFilled
                                  fill="rgb(182, 186, 198)"
                                  width={20}
                                  height={20}
                                  className={styles['icon-button']}
                                />
                              ) : (
                                <Star
                                  fill="rgb(182, 186, 198)"
                                  width={20}
                                  height={20}
                                  className={styles['icon-button']}
                                />
                              )}
                            </IconButton>
                          </Tooltip>
                        )}
                        {providerItem.provider.is_active &&
                          !(
                            selectedClient.client_id === CLIENT_ID &&
                            providerItem.provider.type === MiscProviderType.CREDENTIALS
                          ) && (
                            <IconButton
                              onClick={async (e) => {
                                if (!userId) return;
                                e.stopPropagation();
                                await deactivateProvider({
                                  body: {
                                    providers: [
                                      {
                                        id: String(providerItem.provider.id),
                                        client_id: providerItem.provider.client_id,
                                      },
                                    ],
                                  },
                                  client_id: selectedClient.client_id,
                                });
                                dispatch(
                                  clientApi.endpoints.getApplicationById.initiate(
                                    { client_id: selectedClient.client_id, user_id: userId },
                                    {
                                      subscribe: false,
                                      forceRefetch: true,
                                    },
                                  ),
                                );
                              }}
                              className={styles['icon-button-wrapper']}
                            >
                              <CloseIcon className={styles['icon-button']} />
                            </IconButton>
                          )}
                      </div>
                    );
                  })}
              </div>
            </AccordionDetails>
          </Accordion>
          <EditApplicationFooter
            coverSrc={coverSrc}
            applicationFormChanged={applicationFormChanged}
            avatarSrc={avatarSrc}
            savedCallback={savedCallback}
            setSaveModalOpen={setSaveModalOpen}
          />
        </form>
      </FormProvider>
      <EditProviders
        close={() => setProviderModalOpen(false)}
        clearSelectedProvider={() => setSelectedProvider(undefined)}
        isOpen={providerModalOpen}
        selectedProvider={selectedProvider}
        providerType={ProviderScope.login}
      />
      <Modal open={saveModalOpen} onClose={closeSaveModal}>
        <div className={styles['save-modal']}>
          <div style={{ display: 'flex' }}>
            <Typography className={clsx('header-2-medium', 'font-golos', 'color-0B1641')}>
              Сохранение изменений
            </Typography>
            <IconButton onClick={closeSaveModal} style={{ marginLeft: 'auto', marginBottom: 16 }}>
              <CloseIcon />
            </IconButton>
          </div>
          <Typography style={{ marginBottom: 32 }} className={clsx('text-14', 'color-0B1641')}>
            Изменения не сохранены. Продолжить без сохранения?
          </Typography>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button variant="custom" color="secondary" onClick={closeSaveModal}>
              Отмена
            </Button>
            <Button
              onClick={() => {
                savedCallback.current?.();
                dispatch(setApplicationFormChanged(false));
                setSaveModalOpen(false);
              }}
              variant="custom"
              style={{ marginLeft: 24 }}
            >
              Продолжить
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};
