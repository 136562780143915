import React from 'react';
import { TopBar } from 'idtrusted-topbar';
import {
  COPYRIGHT,
  CUSTOM_STYLES,
  LOGO_URL,
  MANUAL_URL,
  PROJECT_NAME,
  TRUSTED_VERSION,
} from '../constants';
import { getImageURL } from '../helpers';
import { ReactComponent as Avatar } from '../icons/Avatar.svg';
import { ReactComponent as AppIcon } from '../icons/App.svg';
import { sendLogoutRequest } from '../requests/oidc';
import { useLocation, useNavigate } from 'react-router-dom-v5-compat';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import { Roles } from '../enums';

export const TopBarWrapper = () => {
  const navigate = useNavigate();
  const location = useLocation().pathname;
  const user = useSelector((state: RootState) => state.user.userProfile);
  const disableMenu = !!user && (user.password_change_required || user.deleted);

  const borderRadius = CUSTOM_STYLES?.button?.borderRadius;
  const customStylesProps = {
    ...(borderRadius && { borderRadius }),
    ...CUSTOM_STYLES?.button?.primary,
  };

  const sectionName = location.startsWith(`/profile`)
    ? 'Паспорт'
    : location.startsWith(`/applications`)
    ? 'Приложения'
    : '';

  return (
    <TopBar
      role={user.role || Roles.USER}
      items={[
        {
          title: 'Паспорт',
          onClick: () => {
            navigate('profile');
          },
          icon: <Avatar />,
          allowedRoles: [Roles.ADMIN, Roles.EDITOR, Roles.OWNER, Roles.USER, Roles.TRUSTED_USER],
        },
        {
          title: 'Приложения',
          onClick: () => {
            navigate('applications');
          },
          icon: <AppIcon fill="#0B1641" />,
          allowedRoles: [Roles.ADMIN, Roles.EDITOR, Roles.OWNER],
        },
      ]}
      copyright={COPYRIGHT}
      logoUrl={LOGO_URL}
      manualUrl={MANUAL_URL}
      menuButtonDisabled={!!disableMenu}
      onExitClick={sendLogoutRequest}
      projectName={PROJECT_NAME}
      version={TRUSTED_VERSION}
      avatarUrl={getImageURL(user?.picture)}
      customStyles={customStylesProps}
      sectionName={sectionName}
    />
  );
};
