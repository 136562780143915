import React, { FC } from 'react';
import styles from './ProfileField.module.css';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import { ReactComponent as Star } from '../../icons/Star.svg';
import { ReactComponent as StarFilled } from '../../icons/StarFilled.svg';
import { ReactComponent as Pen } from '../../icons/Pen.svg';
import { ReactComponent as PenFilled } from '../../icons/PenFilled.svg';
import { ReactComponent as Lock } from '../../icons/Lock.svg';
import { ReactComponent as World } from '../../icons/World.svg';
import { ReactComponent as Group } from '../../icons/Group.svg';
import { ReactComponent as One } from '../../icons/One.svg';
import { ReactComponent as OneFilled } from '../../icons/OneFilled.svg';
import { ReactComponent as Basket } from '../../icons/Basket.svg';
import { ReactComponent as Id } from '../../icons/Id.svg';
import { TProfileField, useDeleteProfileFieldMutation } from '../../redux/services/settings';
import { EClaimPrivacy } from '../profile/PublicStatusPopover';

type ProfileFieldProps = {
  profile: TProfileField;
  onClick: () => void;
  deleted?: boolean;
};

export const ProfileField: FC<ProfileFieldProps> = ({ profile, onClick, deleted }) => {
  const [deleteProfileField] = useDeleteProfileFieldMutation();

  const required = profile.required;
  const editable = profile.editable;
  const unique = profile.unique;
  const active = profile.active;
  const allowed_as_login = profile.allowed_as_login;

  const GetIconsWithTooltip = (childrenIcon: React.ReactElement, tooltipText: string) => {
    return (
      <Tooltip
        placement="bottom"
        arrow
        title={tooltipText}
        classes={{
          tooltip: styles['input-tooltip'],
          arrow: styles['input-tooltip-arrow'],
        }}
      >
        {childrenIcon}
      </Tooltip>
    );
  };

  let claimIcon = GetIconsWithTooltip(
    <Lock className={styles['profile-icon']} />,
    'Доступно только вам',
  );
  if (profile.claim === EClaimPrivacy.publicGravatar) {
    claimIcon = GetIconsWithTooltip(<World className={styles['profile-icon']} />, 'Доступно всем');
  }
  if (profile.claim === EClaimPrivacy.publicOauth) {
    claimIcon = GetIconsWithTooltip(
      <Group className={styles['profile-icon']} />,
      'Доступно по запросу',
    );
  }

  const handleDelete = async (event: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    event.stopPropagation();
    if (profile.type === 'custom') {
      await deleteProfileField(profile.field);
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    if (profile.field === 'sub') {
      event.preventDefault();
      return;
    }
    onClick();
  };

  return (
    <ListItem
      className={`${styles['profile-item']} ${!active ? styles['inactive'] : ''}`}
      onClick={handleClick}
    >
      <div className={styles['profile-content']}>
        <div className={styles['profile-head']}>
          <Typography className={styles['profile-title']}>{profile.title}</Typography>
          <Typography className={styles['profile-field']}>{profile.field}</Typography>
        </div>
        <div className={styles['profile-icons']}>
          {allowed_as_login &&
            GetIconsWithTooltip(
              <Id className={styles['profile-icon']} />,
              'Используется как логин',
            )}
          {unique
            ? GetIconsWithTooltip(<OneFilled className={styles['profile-icon']} />, 'Уникальное')
            : GetIconsWithTooltip(<One className={styles['profile-icon']} />, 'Не уникальное')}
          {editable
            ? GetIconsWithTooltip(<PenFilled className={styles['profile-icon']} />, 'Редактируемое')
            : GetIconsWithTooltip(<Pen className={styles['profile-icon']} />, 'Не редактируемое')}
          {required
            ? GetIconsWithTooltip(<StarFilled className={styles['profile-icon']} />, 'Обязательное')
            : GetIconsWithTooltip(<Star className={styles['profile-icon']} />, 'Не обязательное')}
          {claimIcon}
          {deleted &&
            GetIconsWithTooltip(
              <Basket className={styles['profile-icon']} onClick={handleDelete} />,
              'Удалить',
            )}
        </div>
      </div>
    </ListItem>
  );
};
