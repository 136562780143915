import React, { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';
import styles from './UserProfile.module.css';
import clsx from 'clsx';
import { Button } from '@mui/material';
import { getSettingsUser, setSettingsUser } from '../../requests/user';

/**
 * Блок Приватность профиля
 */
export const PublicProfileBlock = ({ onOpenDrawer }: { onOpenDrawer: () => void }) => {
  const [profilePrivacy, setProfilePrivacy] = useState(false);

  useEffect(() => {
    const fetchSettings = async () => {
      const settings = await getSettingsUser();
      if (settings) {
        setProfilePrivacy(settings.profile_privacy);
      }
    };
    fetchSettings();
  }, []);

  const handlePrivacyChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const newPrivacy = event.target.checked;
    try {
      await setSettingsUser({ profile_privacy: newPrivacy });
      setProfilePrivacy(newPrivacy);
    } catch (e) {
      console.log('setSettingsUser error: ', e);
    }
  };

  return (
    <div className={styles.panel} style={{ paddingBottom: 22 }}>
      <div style={{ paddingTop: 22 }}>
        <div style={{ paddingBottom: 22 }}>
          <Typography className={clsx('header-3', 'font-golos')}>Приватность профиля</Typography>
        </div>
        <div style={{ display: 'flex', paddingRight: 32 }}>
          <Typography className={clsx('text-14', styles['info-item-value'])}>
            Приватный профиль
          </Typography>
          <Switch
            checked={profilePrivacy}
            onChange={handlePrivacyChange}
            color="primary"
            inputProps={{ 'aria-label': 'Приватный профиль' }}
            title="Переключить приватность профиля"
          />
        </div>
        <Typography className={clsx('text-12', 'color-858BA0')}>
          Запретить отдавать публичный профиль, даже если у поля уровень
          <br />
          публичности “Доступно всем”
        </Typography>
        {!profilePrivacy && (
          <Button
            variant="custom2"
            style={{ marginLeft: 'auto', paddingTop: 22 }}
            className={styles['margin-right']}
            onClick={onOpenDrawer}
            title="Открыть окно с публичным профилем"
          >
            Публичные данные
          </Button>
        )}
      </div>
    </div>
  );
};
