import React, { FC, useEffect, useState } from 'react';
import styles from './ExternalAccount.module.css';
import { useDispatch } from 'react-redux';
import { TUserProfile } from '../../redux/userSlice';
import {
  TExternalAccount,
  useDeleteExternalAccountMutation,
  AccountTypes,
  useSetMainEmailMutation,
} from '../../redux/services/user';
import { TUser } from '../../redux/services/client';
import { BACKEND_URL, GRAVATAR_URL } from '../../constants';
import { getSHA256Hash, getExternalAccountLabel } from '../../helpers';
import { EClaimPrivacy, PublicStatusPopover } from './PublicStatusPopover';
import clsx from 'clsx';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { ReactComponent as IdIcon } from '../../icons/Id.svg';
import { ReactComponent as LdapIcon } from '../../icons/Ldap.svg';
import { ReactComponent as MailIcon } from '../../icons/Mail.svg';
import { ReactComponent as _1CIcon } from '../../icons/1c.svg';
import { ReactComponent as IdmIcon } from '../../icons/Idm.svg';
import aldProIcon from '../../icons/ald-pro.png';
import Jazzicon, { jsNumberForAddress } from 'react-jazzicon';
import { setToggleUpdateUsers } from '../../redux/appSlice';
import { useNavigate } from 'react-router-dom-v5-compat';
import { ExternalAccountActionPopover } from './ExternalAccountActionPopover';

type ExternalAccountProps = {
  account: Omit<TExternalAccount, 'user' | 'user_id'>;
  userProfile: TUserProfile | Partial<TUser>;
  public_accounts_claims_oauth: number[] | undefined;
  public_accounts_claims_gravatar: number[] | undefined;
  clientId?: string;
  toggleUpdateUsers?: boolean;
  withoutButtons?: boolean;
};

export const ExternalAccount: FC<ExternalAccountProps> = ({
  account,
  userProfile: { email: mainEmail, id: userId },
  public_accounts_claims_oauth,
  public_accounts_claims_gravatar,
  clientId,
  toggleUpdateUsers,
  withoutButtons = false,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [gravatarAvatar, setGravatarAvatar] = useState<string | null>(null);
  const [deleteExternalAccount] = useDeleteExternalAccountMutation();
  const [setMainEmail] = useSetMainEmailMutation();

  useEffect(() => {
    if (account.type === AccountTypes.EMAIL) setEmailAvatarFromGravatar(account.email as string);
  }, []);

  const setEmailAvatarFromGravatar = async (email: string) => {
    const hash = await getSHA256Hash(email);
    return setGravatarAvatar(`${GRAVATAR_URL}/${hash}?s=${40}&d=404&r=g`);
  };

  const getAvatar = () => {
    if (account.avatar)
      return (
        <img className={styles['provider-icon']} src={account.avatar} width="40" height="40" />
      );

    switch (account.type) {
      case AccountTypes.EMAIL:
        return gravatarAvatar ? (
          <img
            onError={() => {
              setGravatarAvatar(null);
            }}
            className={styles['provider-icon']}
            src={gravatarAvatar}
            width="40"
            height="40"
          />
        ) : (
          <MailIcon />
        );
      case AccountTypes.SMS:
        return <AccountTypesBlock pathToImage="/public/images/provider/sms.svg" />;
      case AccountTypes.KLOUD:
        return <AccountTypesBlock pathToImage="/public/images/provider/kloud.svg" />;
      case AccountTypes.YANDEX:
        return <AccountTypesBlock pathToImage="/public/images/provider/yandex.svg" />;
      case AccountTypes.VK:
        return <AccountTypesBlock pathToImage="/public/images/provider/vk.svg" />;
      case AccountTypes.GOOGLE:
        return <AccountTypesBlock pathToImage="/public/images/provider/google.svg" />;
      case AccountTypes.MAILRU:
        return <AccountTypesBlock pathToImage="/public/images/provider/mail.svg" />;
      case AccountTypes.QRCODE:
        return <AccountTypesBlock pathToImage="/public/images/provider/qrcode.svg" />;
      case AccountTypes.ESIA:
        return <AccountTypesBlock pathToImage="/public/images/provider/esia.png" />;
      case AccountTypes.LDAP:
        return <LdapIcon />;
      case AccountTypes.ALDPRO:
        return (
          <div
            style={{
              backgroundImage: `url(${aldProIcon})`,
              backgroundSize: 'cover',
              width: 38,
              height: 38,
            }}
          />
        );
      case AccountTypes._1C:
        return <_1CIcon />;
      case AccountTypes.IDM:
        return <IdmIcon width={40} height={40} />;
      case AccountTypes.ETHEREUM:
        return <Jazzicon diameter={40} seed={jsNumberForAddress(account.sub as string)} />;
      default:
        return <IdIcon />;
    }
  };

  const historyPush = () => {
    if (clientId) navigate(`/applications/user/email/change/${clientId}/${userId}`);
    else navigate('/profile/email/change');
  };

  const handleDeleteClick = async () => {
    await deleteExternalAccount({ userId: userId as number, accountId: account.id });
    setAnchorEl(null);
  };

  const handleSetMainEmail = async (email: string) => {
    await setMainEmail({ email, userId: String(userId) });
    setAnchorEl(null);

    if (clientId) {
      dispatch(setToggleUpdateUsers(!toggleUpdateUsers));
    }
  };

  const getExternakAccountClaimPrivacy = () => {
    if (public_accounts_claims_gravatar?.includes(parseInt(account.id, 10)))
      return EClaimPrivacy.publicGravatar;
    if (public_accounts_claims_oauth?.includes(parseInt(account.id, 10)))
      return EClaimPrivacy.publicOauth;
    return EClaimPrivacy.private;
  };

  return (
    <div className={styles['info-item']} style={{ justifyContent: 'space-between' }}>
      <div style={{ display: 'flex', width: withoutButtons ? '95%' : 'calc(100% - 119px)' }}>
        <div className={styles['provider-icon']}>{getAvatar()}</div>
        <div className={styles['provider-info']}>
          <Typography className={clsx('text-14', 'color-B1641', styles.ellipsis)}>
            {getExternalAccountLabel(account)}
          </Typography>
          <div style={{ display: 'flex' }}>
            <Typography className={clsx('text-12', 'color-858BA0')}>
              {account.type === AccountTypes._1C ? '1C' : account.type}
            </Typography>
            {account.profile_link && (
              <Typography style={{ marginLeft: 16 }} className={clsx('text-12', 'color-4C6AD4')}>
                {account.profile_link}
              </Typography>
            )}
          </div>
        </div>
      </div>
      {!!(public_accounts_claims_oauth && public_accounts_claims_oauth) && (
        <div style={{ display: 'flex' }}>
          {!withoutButtons && (
            <ExternalAccountActionPopover anchorEl={anchorEl} setAnchorEl={setAnchorEl}>
              <Button
                className={clsx(styles['popover-button'])}
                onClick={() => {
                  account.type === AccountTypes.EMAIL && account.email === mainEmail
                    ? historyPush()
                    : handleDeleteClick();
                }}
              >
                <Typography className={clsx('text-14', 'color-0B1641')}>
                  {account.type === AccountTypes.EMAIL && account.email === mainEmail
                    ? 'Изменить'
                    : 'Удалить'}
                </Typography>
              </Button>
              {account.type === AccountTypes.EMAIL && account.email !== mainEmail && (
                <Button
                  className={clsx(styles['popover-button'])}
                  onClick={() => {
                    if (account.email) handleSetMainEmail(account.email);
                  }}
                >
                  <Typography className={clsx('text-14', 'color-0B1641')}>
                    Сделать основной
                  </Typography>
                </Button>
              )}
            </ExternalAccountActionPopover>
          )}
          <PublicStatusPopover
            userId={String(userId)}
            claimPrivacy={getExternakAccountClaimPrivacy()}
            externalAccountId={parseInt(account.id, 10)}
          />
        </div>
      )}
    </div>
  );
};

type AccountTypesProps = {
  pathToImage: string;
};

const AccountTypesBlock: FC<AccountTypesProps> = ({ pathToImage }) => {
  return (
    <div
      style={{
        backgroundImage: `url(${BACKEND_URL + pathToImage})`,
      }}
      className={styles['provider-icon-wrapper']}
    />
  );
};
